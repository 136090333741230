import { useState } from 'react'
import { useColorScheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import { Chip, Popover, Stack, Typography, List, ListItem, ListItemText } from '@mui/material'

const ReportMultipleMatchesDisplay = ({ multipleMatches }) => {
  const { mode } = useColorScheme()
  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedMatch, setSelectedMatch] = useState(null)

  const handleClick = (e, match) => {
    setAnchorEl(e.currentTarget)
    setSelectedMatch(match)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setSelectedMatch(null)
  }

  const open = Boolean(anchorEl)

  return (
    <Box>
      <Typography>Multiple marches Found:</Typography>
      <Stack
        direction="row"
        spacing={1}>
        {multipleMatches.map((match, index) => (
          <Chip
            key={index}
            label={match.searchTerm}
            onClick={(e) => handleClick(e, match)}
            className="cursor-pointer bg-blue-100 hover:bg-blue-200"
          />
        ))}
      </Stack>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}>
        <Box sx={{ p: 2, backgroundColor: mode === 'dark' ? '#333' : '#efefef' }}>
          <Typography sx={{ fontWeight: 'bold', mb: 1 }}>Possible matches for "{selectedMatch?.searchTerm}"</Typography>
          <List sx={{ maxHeight: 300, overflow: 'auto', py: 0 }}>
            {selectedMatch?.matches.map((match, index) => (
              <ListItem
                key={index}
                sx={{ py: 0, mb: 1 }}>
                <ListItemText primary={match} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Popover>
    </Box>
  )
}

export default ReportMultipleMatchesDisplay
