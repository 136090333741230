import { useState } from 'react'
import { useLazyGetSerialNumbersQuery } from '../equipments/equipmentsApiSlice'
import Grid from '@mui/material/Unstable_Grid2'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import BeatLoader from 'react-spinners/BeatLoader'
import ReportsList from './ReportsList'
import { toast } from 'react-toastify'
import { useColorScheme } from '@mui/material/styles'
import ReportMultipleMatchesDisplay from './ReportMultipleMatchesDisplay'

const ReportSerialNumber = () => {
  const { mode } = useColorScheme()
  const reportType = 'Serial Numbers'
  const [showTable, setShowTable] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [getSerialNumbers, { data, isLoading, isFetching, isError, error }] = useLazyGetSerialNumbersQuery({})

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!inputValue.trim()) {
      return
    }

    try {
      const filteredNumbersArray = inputValue
        .split('\n')
        .map((serial) => serial.trim())
        .filter((serial) => serial !== '')
      const result = await getSerialNumbers({ filteredNumbersArray, reportType })

      setInputValue('')
      setShowTable(true)

      if (result.data?.totalFound > 0) {
        toast.success(`Report generated. ${result.data?.totalFound} of ${result.data?.totalRequested} serial numbers found.`, { autoClose: 5000 })
      } else {
        toast.warning('No matching records found.')
      }
    } catch (err) {
      console.error('Failed to generate report:', err)
      toast.error(err.data?.message || 'Failed to generate report')
      setShowTable(false)
    }
  }

  const handleReset = () => {
    setShowTable(false)
    setInputValue('')
  }

  const columns = [
    {
      field: 'distributorName',
      headerName: 'Distributor',
      maxWidth: 200,
      minWidth: 100,
      width: 125,
      resizable: false,
      hideable: false
    },
    {
      field: 'registrantAward',
      headerName: 'Registrant Award',
      maxWidth: 200,
      minWidth: 100,
      width: 125,
      sortable: false,
      resizable: false
    },
    {
      field: 'registrantCardOrdered',
      headerName: 'Registrant Card Ordered',
      valueGetter: (params) => (params.row.registrantCardOrdered ? new Date(params.row.registrantCardOrdered).toLocaleDateString('en-US', { timeZone: 'UTC' }) : ''),
      maxWidth: 200,
      minWidth: 100,
      width: 125,
      sortable: false,
      resizable: false
    },
    {
      field: 'registrantFirstName',
      headerName: 'Registrant First Name',
      maxWidth: 200,
      minWidth: 100,
      width: 125,
      sortable: false,
      resizable: false
    },
    {
      field: 'registrantLastName',
      headerName: 'Registrant Last Name',
      maxWidth: 200,
      minWidth: 100,
      width: 125,
      sortable: false,
      resizable: false
    },
    {
      field: 'registrantEmail',
      headerName: 'Registrant Email',
      maxWidth: 200,
      minWidth: 100,
      width: 125,
      sortable: false,
      resizable: false
    },
    {
      field: 'registrantMoneyLoaded',
      headerName: 'Registrant Money Loaded',
      valueGetter: (params) => (params.row.registrantMoneyLoaded ? new Date(params.row.registrantMoneyLoaded).toLocaleDateString('en-US', { timeZone: 'UTC' }) : ''),
      maxWidth: 200,
      minWidth: 100,
      width: 125,
      sortable: false,
      resizable: false
    },
    {
      field: 'registrationDate',
      headerName: 'Registration Date',
      maxWidth: 200,
      minWidth: 100,
      width: 125,
      valueGetter: (params) => (params.row.registrationDate ? new Date(params.row.registrationDate).toLocaleDateString('en-US', { timeZone: 'UTC' }) : ''),
      sortable: false,
      resizable: false
    },
    {
      field: 'serialNumber',
      headerName: 'Serial #',
      maxWidth: 200,
      minWidth: 100,
      width: 125,
      sortable: false,
      resizable: false
    },
    {
      field: 'salesFirstName',
      headerName: 'Sales First Name',
      maxWidth: 200,
      minWidth: 100,
      width: 125,
      sortable: false,
      resizable: false
    },
    {
      field: 'salesLastName',
      headerName: 'Sales Last Name',
      maxWidth: 200,
      minWidth: 100,
      width: 125,
      sortable: false,
      resizable: false
    },
    {
      field: 'salesEmail',
      headerName: 'Sales Email',
      maxWidth: 200,
      minWidth: 100,
      width: 125,
      sortable: false,
      resizable: false
    },
    {
      field: 'salesAward',
      headerName: 'Sales Award',
      maxWidth: 200,
      minWidth: 100,
      width: 125,
      sortable: false,
      resizable: false
    },
    {
      field: 'salesCardOrdered',
      headerName: 'Sales Card Ordered',
      valueGetter: (params) => (params.row.salesCardOrdered ? new Date(params.row.salesCardOrdered).toLocaleDateString('en-US', { timeZone: 'UTC' }) : ''),
      maxWidth: 200,
      minWidth: 100,
      width: 125,
      sortable: false,
      resizable: false
    },
    {
      field: 'salesMoneyLoaded',
      headerName: 'Sales Money Loaded',
      valueGetter: (params) => (params.row.salesMoneyLoaded ? new Date(params.row.salesMoneyLoaded).toLocaleDateString('en-US', { timeZone: 'UTC' }) : ''),
      maxWidth: 200,
      minWidth: 100,
      width: 125,
      sortable: false,
      resizable: false
    }
  ]

  return (
    <>
      {!showTable && (
        <Grid sx={{ mb: 2 }}>
          <p>
            Enter a list of serial numbers to create a table of those equipment. <small xs={{ color: 'lightgray' }}>One serial number per line.</small>
          </p>

          <Box>
            <form onSubmit={handleSubmit}>
              <TextField
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                id="outlined-multiline-static"
                label=""
                multiline
                rows={10}
                sx={{ mb: 2 }}
                placeholder="Enter serial number(s) (one per line)..."
              />
              <Grid xs="auto">
                {!isFetching && (
                  <Button
                    variant="contained"
                    sx={{ mr: 1 }}
                    type="submit"
                    disabled={!inputValue}>
                    Generate Report
                  </Button>
                )}
                {isFetching && (
                  <Button
                    variant="contained"
                    sx={{ mr: 1 }}
                    type="submit"
                    disabled>
                    Generate Report
                  </Button>
                )}
              </Grid>
            </form>
          </Box>
        </Grid>
      )}

      {isLoading || isFetching ? (
        <BeatLoader
          color={mode === 'light' ? '#222' : '#eFeFeF'}
          size={20}
        />
      ) : isError ? (
        <Typography>{error?.data?.message || 'An error occurred while generating the report.'}</Typography>
      ) : (
        <>
          {showTable && data && (
            <>
              {console.log(data)}
              <ReportsList
                equipments={data?.equipment}
                columns={columns}
                reportType={reportType}
              />
              <Stack
                direction="row"
                spacing={5}
                sx={{
                  alignItems: 'center'
                }}>
                <Button
                  variant="contained"
                  color={mode === 'light' ? 'user' : 'cancel'}
                  onClick={handleReset}>
                  New Search
                </Button>
                {data?.totalRequested && (
                  <Box>
                    <Typography>Total requested:</Typography>
                    <Chip
                      color="success"
                      label={data?.totalRequested}
                      sx={{
                        height: 'auto',
                        '& .MuiChip-label': {
                          display: 'block',
                          whiteSpace: 'normal'
                        },
                        py: 0.5
                      }}
                    />
                  </Box>
                )}
                {data?.totalFound && (
                  <Box>
                    <Typography>Total found:</Typography>
                    <Chip
                      color="success"
                      label={data?.totalFound}
                      sx={{
                        height: 'auto',
                        '& .MuiChip-label': {
                          display: 'block',
                          whiteSpace: 'normal'
                        },
                        py: 0.5
                      }}
                    />
                  </Box>
                )}
                {data.notFoundSerials && data?.notFoundSerials.length > 0 && (
                  <Box>
                    <Typography>Not found:</Typography>
                    <Chip
                      color="info"
                      label={data?.notFoundSerials.join(', ')}
                      sx={{
                        height: 'auto',
                        '& .MuiChip-label': {
                          display: 'block',
                          whiteSpace: 'normal'
                        },
                        py: 0.5
                      }}
                    />
                  </Box>
                )}
                {data?.totalMultipleMatches > 0 && <ReportMultipleMatchesDisplay multipleMatches={data.multipleMatches} />}
              </Stack>
            </>
          )}
        </>
      )}
    </>
  )
}

export default ReportSerialNumber
