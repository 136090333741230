import { useState } from 'react'
import { styled, useColorScheme } from '@mui/material/styles'
import DatePicker from 'react-datepicker'
import Typography from '@mui/material/Typography'
import EventIcon from '@mui/icons-material/Event'
import { useTheme } from '@mui/material'

const generateUniqueId = () => {
  return '_' + Math.random().toString(36).substr(2, 9)
}

const StyledDatePicker = styled('div')(({ theme, disabled, mode, isInModal }) => ({
  '& label': {
    color: theme.vars.palette.inputLabel[mode],
    transition: theme.transitions.create(['color']),
    '&.focused': {
      color: theme.vars.palette.primary.main,
      [theme.getColorSchemeSelector('dark')]: {
        color: theme.vars.palette.primary.light
      }
    },
    ...(disabled && {
      color: '#aaa',
      cursor: 'not-allowed',
      pointerEvents: 'all',
      [theme.getColorSchemeSelector('dark')]: {
        color: '#999'
      }
    })
  },
  '& .react-datepicker': {
    outline: '1px solid #ccc',
    [theme.getColorSchemeSelector('dark')]: {
      outline: '1px solid #555'
    }
  },
  '& .react-datepicker-wrapper': {
    '& input': {
      color: theme.vars.palette.inputText[mode]
    }
  },
  '.react-datepicker__month-container, .react-datepicker__header, .react-datepicker__month': {
    background: theme.vars.palette.inputBackground[mode],
    '& *': {
      color: theme.vars.palette.inputText[mode]
    }
  },
  '& input': {
    backgroundColor: isInModal ? theme.vars.palette.inputBackground.light : theme.vars.palette.customBackground.light,
    outline: '1px solid #ccc',
    border: 'none',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      boxShadow: `rgba(${theme.vars.palette.primary.mainChannel} / 0.25) 0 0 0 0.25rem`,
      [theme.getColorSchemeSelector('dark')]: {
        boxShadow: `rgba(${theme.vars.palette.primary.lightChannel} / 0.25) 0 0 0 0.25rem`
      }
    },
    [theme.getColorSchemeSelector('dark')]: {
      backgroundColor: isInModal ? theme.vars.palette.inputBackground.dark : theme.vars.palette.customBackground.dark,
      outline: '1px solid #555'
    }
  },

  '.react-datepicker__day': {
    '&:hover': {
      backgroundColor: theme.vars.palette.primary.light
    }
  },
  '& .react-datepicker__calendar-icon': {
    color: theme.vars.palette.inputText[mode],
    '&:hover': {
      color: theme.vars.palette.primary.main
    }
  },
  '.react-datepicker__close-icon': {
    '&:after': {
      color: theme.vars.palette.inputText[mode]
    },
    '&:hover': {
      '&:after': {
        color: theme.vars.palette.primary.main
      }
    }
  }
}))

const CustomDatePicker = ({ label, value, onChange, disabled, required, isInModal }) => {
  const { mode } = useColorScheme()
  const theme = useTheme()
  const uniqueId = generateUniqueId()
  const [inputFocused, setInputFocused] = useState(false)
  const minDate = new Date('2016-01-01T00:00:00')

  const handleBlur = () => setInputFocused(false)
  const handleFocus = () => setInputFocused(true)

  return (
    <StyledDatePicker
      disabled={disabled}
      className="styled-datepicker"
      mode={mode}
      isInModal={isInModal}>
      <label
        htmlFor={uniqueId}
        className={inputFocused ? 'focused' : ''}>
        {label}
        {required && (
          <Typography
            component="span"
            sx={{ color: theme.vars.palette.error.main, lineHeight: 1, fontSize: '.85rem', ml: 0.125 }}>
            *
          </Typography>
        )}
      </label>
      <DatePicker
        id={uniqueId}
        disabled={disabled}
        onChange={onChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onCalendarClose={handleBlur}
        onCalendarOpen={handleFocus}
        selected={value}
        minDate={minDate}
        isClearable={value}
        placeholderText="mm/dd/yyyy"
        showIcon
        toggleCalendarOnIconClick
        showPopperArrow={false}
        icon={<EventIcon />}
        required={required}></DatePicker>
    </StyledDatePicker>
  )
}

export default CustomDatePicker
