import { useState } from 'react'
import { useLazyGetFilteredExpiredEquipmentsQuery } from '../equipments/equipmentsApiSlice'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Unstable_Grid2'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import ReportsList from './ReportsList'
import BeatLoader from 'react-spinners/BeatLoader'
import { useTheme } from '@mui/material'
import { useColorScheme } from '@mui/material/styles'

const ReportExpiration = () => {
  const { mode } = useColorScheme()
  const theme = useTheme()
  const reportType = 'Expiration'
  const [type, setType] = useState('')
  const [showTable, setShowTable] = useState(false)
  const [getFilteredExpiredEquipment, { data, isLoading, isFetching, isError, error }] = useLazyGetFilteredExpiredEquipmentsQuery({ type })

  const handleFilterSubmit = async (e) => {
    e.preventDefault()
    await getFilteredExpiredEquipment({ type, reportType })
    setShowTable(true)
  }

  const columns = [
    {
      field: 'distributorName',
      headerName: 'Distributor',
      maxWidth: 200,
      minWidth: 100,
      flex: 1,
      sortable: false,
      resizable: false,
      hideable: false
    },
    {
      field: 'shippingDate',
      headerName: 'Shipping Date',
      maxWidth: 200,
      minWidth: 100,
      flex: 1,
      valueGetter: (params) => (params.row.expirationDate ? new Date(params.row.expirationDate).toLocaleDateString('en-US', { timeZone: 'UTC' }) : ''),
      sortable: false,
      resizable: false,
      hide: true,
      visible: false
    },
    {
      field: 'installationDate',
      headerName: 'Installation Date',
      maxWidth: 200,
      minWidth: 100,
      flex: 1,
      valueGetter: (params) => (params.row.installationDate ? new Date(params.row.installationDate).toLocaleDateString('en-US', { timeZone: 'UTC' }) : ''),
      sortable: false,
      resizable: false
    },
    {
      field: 'expirationDate',
      headerName: 'Expiration Date',
      maxWidth: 200,
      minWidth: 100,
      flex: 1,
      valueGetter: (params) => (params.row.expirationDate ? new Date(params.row.expirationDate).toLocaleDateString('en-US', { timeZone: 'UTC' }) : ''),
      sortable: false,
      resizable: false,
      sortDescFirst: true,
      sortingFn: 'datetime'
    },
    {
      field: 'customerName',
      headerName: 'Customer Name',
      valueGetter: (params) => (params.row.customer ? params.row.customer.name : ''),
      sortable: false,
      resizable: false
    },
    {
      field: 'customerAddress',
      headerName: 'Customer Address',
      valueGetter: (params) => (params.row.customer ? params.row.customer.address : ''),
      sortable: false,
      resizable: false
    },
    {
      field: 'customerAddress2',
      headerName: 'Customer Address 2',
      valueGetter: (params) => (params.row.customer ? params.row.customer.address2 : ''),
      sortable: false,
      resizable: false
    },
    {
      field: 'customerCity',
      headerName: 'Customer City',
      valueGetter: (params) => (params.row.customer ? params.row.customer.city : ''),
      sortable: false,
      resizable: false
    },
    {
      field: 'customerState',
      headerName: 'Customer State',
      valueGetter: (params) => (params.row.customer ? params.row.customer.state : ''),
      sortable: false,
      resizable: false
    },
    {
      field: 'customerZip',
      headerName: 'Customer Zip',
      valueGetter: (params) => {
        const zip = params?.row?.customer?.zip
        return zip ? zip.toString().padStart(5, '0') : ''
      },
      sortable: false,
      resizable: false
    },
    {
      field: 'customerPhone',
      headerName: 'Customer Phone',
      valueGetter: (params) => (params.row.customer ? params.row.customer.phone : ''),
      sortable: false,
      resizable: false
    },
    {
      field: 'customerEmail',
      headerName: 'Customer Email',
      valueGetter: (params) => (params.row.customer ? params.row.customer.email : ''),
      sortable: false,
      resizable: false
    },
    {
      field: 'purchaseOrder',
      headerName: 'Purchase Order #',
      sortable: false,
      resizable: false
    },
    {
      field: 'unitModel',
      headerName: 'Unit Model',
      maxWidth: 200,
      minWidth: 100,
      flex: 1,
      sortable: false,
      resizable: false
    },
    {
      field: 'compressorHorsepower',
      headerName: 'Compressor Horsepower',
      maxWidth: 200,
      minWidth: 100,
      flex: 1,
      sortable: false,
      resizable: false
    },
    {
      field: 'serialNumber',
      headerName: 'Serial #',
      maxWidth: 200,
      minWidth: 100,
      flex: 1,
      sortable: false,
      resizable: false
    },
    {
      field: 'serialNumberOld',
      headerName: 'Old Serial Number',
      sortable: false,
      resizable: false
    }
  ]

  return (
    <>
      <Grid
        container
        spacing={2}
        marginTop={1}
        marginBottom={1}
        alignItems="flex-end">
        <Grid xs="auto">
          <Box>
            <FormControl sx={{ width: 400, mb: 0 }}>
              <InputLabel
                id="report-type"
                sx={{ backgroundColor: '#FFF', [theme.getColorSchemeSelector('dark')]: { backgroundColor: '#121212' }, px: 1 }}>
                Choose an expiration type
              </InputLabel>
              <Select
                labelId="report-type"
                variant="outlined"
                value={type}
                onChange={(e) => setType(e.target.value)}>
                <MenuItem value="expired">Expired</MenuItem>
                <MenuItem value="thirtyDays">1 - 30 Days</MenuItem>
                <MenuItem value="sixtyDays">31 - 60 Days</MenuItem>
                <MenuItem value="ninetyDays">60 - 90 Days</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid xs="auto">
          {!isFetching && (
            <Button
              variant="contained"
              sx={{ mr: 1 }}
              onClick={handleFilterSubmit}
              disabled={type ? false : true}>
              Submit
            </Button>
          )}
          {isFetching && (
            <Button
              variant="contained"
              sx={{ mr: 1 }}
              onClick={handleFilterSubmit}
              disabled>
              Submit
            </Button>
          )}
        </Grid>
      </Grid>

      {isLoading || isFetching ? (
        <BeatLoader
          color={mode === 'light' ? '#222' : '#eFeFeF'}
          size={20}
          style={{ margin: '8px' }}
        />
      ) : isError ? (
        <Typography>{error.data.message}</Typography>
      ) : (
        <>
          {showTable && (
            <ReportsList
              equipments={data}
              columns={columns}
              reportType={reportType}
              hideColumns={{ shippingDate: false }}
            />
          )}
        </>
      )}
    </>
  )
}

export default ReportExpiration
